import { useLayoutEffect, useState } from "react";

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
    const handleSize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
    };
    // useLayoutEffect вызывается каждый раз, когда происходит мутация DOM 
    // или когда что-то меняется в документе
    useLayoutEffect(() => {
        handleSize();
        window.addEventListener("resize", handleSize);
        return () => window.removeEventListener("resize", handleSize);
    }, [])

    return windowSize;
}

export default useWindowSize;